<template>
  <div
    class="meeting px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent
          class="py-8"
          title="October 2022 Meeting"
          size="large"
        />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2 pr-3">
            <h2
              class="mb-6"
              style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
            >
              ATTENDEES
            </h2>
            <p class="mb-4">
              Below is a list of some of the entities represented at the
              meeting:
            </p>
            <ul class="list-disc p-light ml-10">
              <li>Lawrence Livermore National Labs</li>
              <li>Northrop Grumman Innovation Systems - Promontory</li>
              <li>Tooele Army Depot</li>
              <li>OLIN-WINCHESTER</li>
              <li>Brigham Young University</li>
              <li>Northrop Grumman Innovation Systems - Bacchus</li>
              <li>Dyno Nobel</li>
              <li>Northrop Grumman Corporation-ABL</li>
              <li>Northrop Grumman Innovation Systems - Corporate</li>
              <li>Northop Grumman Corporation</li>
              <li>Sandia National Laboratories</li>
              <li>
                New Mexico Tech - Energetic Materials Research and Testing
                Center
              </li>
              <li>Federal Bureau of Investigations</li>
              <li>Hill Air Force Base</li>
              <li>
                Sporting Arms and Ammunition Manufacturers’ Institute (DG
                Advisor, LLC.)
              </li>
              <li>Lawrence Livermore National Laboratory</li>
              <li>Naval Ordnance Safety and Security Activity</li>
              <li>Northrop Grumman Corporation - Defense Systems</li>
              <li>Winchester - East Alton</li>
              <li>Safety Management Services, Inc.</li>
            </ul>
          </div>
          <div class="w-full md:w-1/2 pl-3">
            <div class="mb-6">
              <h2
                class="mb-6"
                style="font-size:22.5px;line-height: 30.938px;font-weight:bold;"
              >
                MEETING PROCEEDINGS
              </h2>
              <div
                style="border-color:#e7e4e2;border-width:0px;background-color:#ffffff;"
                class="px-6 py-4 mb-4 fusion-panel panel-default fusion-toggle-no-divider fusion-toggle-boxed-mode"
                role="tabpanel"
              >
                <div class="panel-heading pl-6">
                  <h4
                    class="panel-title toggle"
                    style="position:relative;"
                    data-fontsize="16"
                    data-lineheight="22"
                  >
                    <a
                      @click="showContent = showContent === 1 ? false : 1"
                      :class="{ open: showContent === 1 }"
                      style="cursor:pointer;"
                      aria-expanded="false"
                      aria-selected="false"
                      aria-controls="811950d795ab93cb4"
                      role="tab"
                      data-toggle="collapse"
                    >
                      <span
                        class="fusion-toggle-icon-wrapper"
                        aria-hidden="true"
                      >
                        <i class="fa-fusion-box"></i>
                      </span>
                      <span
                        class="fusion-toggle-heading font-bold"
                        style="color:#ef9a3d;"
                        >2022 Meeting Proceedings</span
                      >
                    </a>
                  </h4>
                </div>
                <transition name="fade">
                  <div
                    id="811950d795ab93cb4"
                    class="panel-collapse collapse"
                    v-if="showContent === 1"
                  >
                    <div
                      class="flex flex-col panel-body toggle-content fusion-clearfix pt-6"
                    >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F001%20-%20Welcome%20and%20Review%20of%20ET%20Users%20Group%20Charter.pdf?alt=media&token=029ebdc3-5bb7-4432-9449-bbb197fe6272"
                        >Welcome and Review of ET Users Group Charter</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F002%20-%20Safe%20Separation%20Distances%20for%20HD%201.3.pdf?alt=media&token=47d8193e-e5b3-4e2f-9c94-40fb664686a6"
                        >Safe Separation Distances for HD 1.3</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F003%20-%20Koenen%20Tube%20INF%2015%20UK%20US%202022.pdf?alt=media&token=f84baa73-319c-4517-932a-c27902685ba8"
                        >Koenen Tube INF 15 UK US 2022</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F004%20-%20Evaluation%20of%20Shipping%20Pipes%20Used%20to%20Transport%20Explosives%20under%20DOT-SP%208451.pdf?alt=media&token=f88e7409-ca90-4d23-91da-af119af2bf2b"
                        >Evaluation of Shipping Pipes Used to Transport
                        Explosives under DOT-SP 8451</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F005%20-%20%20Introduction%20to%20SAAMI.pdf?alt=media&token=4b0b4d05-44c0-42d2-9432-676f546d4a70"
                        >Introduction to SAAMI</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F006%20-%20Design%20Case%20%E2%80%93%20Use%20of%20Risk%20Management%20Tools%20for%20Establishing%20Safe%20Process%20Operating%20Parameters.pdf?alt=media&token=90467bcb-b864-4f71-9d8c-5a41745825db"
                        >Design Case – Use of Risk Management Tools for
                        Establishing Safe Process Operating Parameters</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F008%20-%20Results%20from%20Hazards%20Testing%20on%20Thermite%20(Phase%20I).pdf?alt=media&token=7292b833-4308-401d-918d-1c82a10cdebd"
                        >Results from Hazards Testing on Thermite (Phase I)</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F009%20-%20SAND2022-13725%20PE%20-%20No.8%20Det%20Comparison%20-%20Application%20of%20the%20Sand%20Crush%20Test.pdf?alt=media&token=4186ae83-9396-444e-b063-4610808825e0"
                        >SAND2022-13725 PE - No.8 Det Comparison - Application
                        of the Sand Crush Test</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F010%20-%20Desensitization%20of%20Explosives.pdf?alt=media&token=a3ce0a1f-a039-4285-91ef-086e9025f2c7"
                        >Desensitization of Explosives</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F011%20-%20Example%20of%20Electrostatic%20Discharge%20Analysis%20in%20Incident%20Investigation.pdf?alt=media&token=f50a8885-287c-429c-92ba-946d93df0690"
                        >Example of Electrostatic Discharge Analysis in Incident
                        Investigation</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F012%20-%20DSC%20Round%20Robin%20--%20New%20Procedure%20Overview%2C%20Sample%20Selection.pdf?alt=media&token=ef7a01d9-529d-4a6e-83c2-2be4937cc0ba"
                        >DSC Round Robin -- New Procedure Overview, Sample
                        Selection</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F013%20-%20Thermal%20Analyses.pdf?alt=media&token=665b0077-09b6-49e4-82d2-8d089323096b"
                        >Thermal Analyses</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F014%20-%20DSC%20Round%20Robin%20--%20Coordination%20and%20Schedule%2C%20Issues%20and%20Resolutions.pdf?alt=media&token=5587923a-a245-42b0-a63c-280a2b9a803c"
                      >
                        DSC Round Robin -- Coordination and Schedule, Issues and
                        Resolutions</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F015%20-%20History%20of%20Hazards%20Analysis%20-%20Before%20HA%20to%201992.pdf?alt=media&token=acd829c4-0b48-4278-af43-eac273e2566f"
                        >History of Hazards Analysis - Before HA to 1992</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F017%20-%20Effect%20of%20Particle%20Size%20Distribution%20and%20Morphology%20on%20Impact%20and%20Friction%20Sensitivity%20of%20PETN.pdf?alt=media&token=9cab6e6e-cc45-4fae-a0d4-490281f77afc"
                        >Effect of Particle Size Distribution and Morphology on
                        Impact and Friction Sensitivity of PETN</a
                      >
                      <a
                        class="mb-2"
                        style="color:#724009;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F018%20-%20Sensitivity%20Test%20Equipment%20Maintenance.pdf?alt=media&token=7239a5e4-48e7-4aa3-84a1-464691fcc6a8"
                        >Sensitivity Test Equipment Maintenance</a
                      >
                      <a
                        class="mb-2"
                        style="color:#ef9a3d;"
                        target="_blank"
                        href="https://firebasestorage.googleapis.com/v0/b/etusersgroup.appspot.com/o/meeting2022%2F019%20-%20In-Process%20Characterization%20Example%20with%20Propellant%20Cutting.pdf?alt=media&token=fa7e4eac-5705-4a56-9b9b-ed02abe00597"
                        >In-Process Characterization Example with Propellant
                        Cutting</a
                      >
                    </div>
                  </div>
                </transition>
              </div>
              <p class="p-light">
                These files are for participants of the ET Users Group. As such
                please consider the information as ‘approved for internal use
                only. Feel free to distribute this information within your
                company or group as needed. If individuals or organizations
                outside your company or group desire access to this information,
                please
                <router-link class="link" to="/contact-us"
                  >Contact Us</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss">
.meeting {
  .fusion-button.button-1 {
    border-radius: 0px;
  }

  .panel-title a .fa-fusion-box:before {
    position: absolute;
    left: -2rem;
    top: 2px;
    content: url("../../assets/plus-solid.svg");
    filter: invert(70%) sepia(90%) saturate(601%) hue-rotate(326deg)
      brightness(94%) contrast(100%);
  }

  .panel-title a.open .fa-fusion-box:before {
    content: url("../../assets/minus-solid.svg");
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .btn:hover {
    background-color: #464d5f !important;
  }
}
</style>
